<template>
  <form
    @submit.prevent="submit"
    v-if="authUser"
    :class="isLoading ? 'is-busy' : ''"
    class="card"
  >
    <div class="card-body">
      <h5 class="card-title">Photo</h5>

      <div class="d-flex align-items-center">
        <UserAvatar :user="authUser" :variant="'big'" class="flex-shrink-0 me-3"></UserAvatar>
        <div class="flex-grow-1">
          <div class="my-3">
            <input
              @change="handleFileChange"
              ref="file"
              type="file"
              class="form-control"
              :class="fileIsValid ? '' : 'is-invalid'"
            >
            <div class="invalid-feedback">Invalid file type and/or size.</div>
            <div class="form-text">
              Max file size: {{ maxSize | byteSize }}.<br>
              Allowed file types: {{ allowedExtensions }}.
            </div>
          </div>

          <ErrorMessage v-if="error" :error="error"></ErrorMessage>

          <SubmitButton
            :text="'Save photo'"
            :textBusy="'Saving...'"
            :isLoading="isLoading"
            :disabled="isLoading || !fileIsValid || file === null"
            class="btn btn-primary"
          ></SubmitButton>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { httpPostMultipartForm } from '@/core/http';

export default {
  name: 'UserPhotoForm',
  components: {
    UserAvatar: () => import('@/components/UserAvatar'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
    fileIsValid() {
      const { file, maxSize, allowedFileTypes } = this;
      if (!file) return true;

      const { size, type } = file;
      if (size > maxSize) return false;
      if (!allowedFileTypes.includes(type)) return false;

      return true;
    },
    allowedExtensions() {
      return this.allowedFileTypes.map((val) => {
        const parts = val.split('/');
        return parts[1];
      }).join(', ');
    },
  },
  methods: {
    handleFileChange() {
      const { files } = this.$refs.file;
      if (files.length) {
        const [file] = files;
        this.file = file;
      }
    },
    async submit() {
      this.isLoading = true;
      this.error = null;
      try {
        const formData = new FormData();
        formData.append('file', this.file);
        const res = await httpPostMultipartForm('/user/photo', formData);
        this.$store.dispatch('auth/updateUserPhoto', res.data);
        this.file = null;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      file: null,
      maxSize: 0.5 * 1024 * 1024,
      allowedFileTypes: [
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'image/png',
        'image/svg+xml',
      ],
    };
  },
};
</script>
